document.querySelector("#sidenav > .overlay").addEventListener('click', function(e) {
  document.querySelector("#sidenav").classList.remove('open');
})

document.querySelector(".menu-btn").addEventListener('click', function(e) {
  document.querySelector("#sidenav").classList.add('open');
})

window.addEventListener("scroll", function() {
  if (window.scrollY > 250) {
    document.querySelector("#navbar").classList.add('sticky')
  } else {
    document.querySelector("#navbar").classList.remove('sticky')
  }
});